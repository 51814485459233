<template>
  <page-template>
    <template #nav>
      <basic-nav>
        <template #nav-center>
          <h1 class="text-3xl">Cash/Coin Acceptor Access</h1>
        </template>
      </basic-nav>
    </template>
    <template #body>
      <div class="w-400 m-auto"  v-if="errorFetching || loading">
        <LoadingCard v-if="loading" />
        <BasicCard  v-if="errorFetching">
          <template slot="body">
            <div class="w-auto m-auto p-3 text-center">
              <font-awesome-icon class="text-orange text-center m-4" size="8x" :icon="['far', 'exclamation-circle']"  />
              <h2 class="text-orange text-3xl mb-4 uppercase text-center">Acceptor Access Currently Not Available</h2>
            </div>
          </template>
        </BasicCard>
      </div>
      <div v-else>
      <div class="flex justify-center">
        <value-display-card
            title="CURRENT ACCEPTOR CASH TOTAL"
            :value="cashTotal"
            class="w-11/12"
        >
          <template #body></template>
        </value-display-card>
      </div>
      <div class="flex justify-between">
        <basic-button title="PRINT RECEIPT" class="mt-10 px-10 w-1/3" button-background-color="bg-gray" @click="snapShot"/>
        <basic-button title="RESET & PRINT RECEIPT" class="mt-10 px-10 w-1/3" @click="confirmModal=true"/>
      </div>
      <div class="modules-div">
        <BasicModal v-if="confirmModal" @close="confirmModal=false">
          <template slot="body">
            <div class="w-400">
              <font-awesome-icon class="text-orange text-center ml-1 mr-2" size="8x" :icon="['far', 'exclamation-circle']"  />
              <h2 class="text-orange text-3xl mb-4 uppercase">Are you finished?</h2>
              <div class="flex justify-evenly">
                <BasicButton @click="confirmModal=false" class="w-1/3" title="NO" button-background-color="bg-gray-500" />
                <BasicButton @click="confirmReset" class="w-1/3" title="YES" />
              </div>
            </div>
          </template>
        </BasicModal>
        <BasicModal v-if="successModal" @close="successModal=false">
          <template slot="body">
            <div class="w-400">
              <font-awesome-icon class="text-orange text-center ml-1 mr-2" size="8x" :icon="['far', 'check']"  />
              <h2 class="text-orange text-4xl mb-4 uppercase">Reset Complete Printing Receipt...</h2>
              <div class="flex justify-evenly">
              </div>
            </div>
          </template>
        </BasicModal>
        </div>
      </div>
    </template>
  </page-template>
</template>

<script>
import PageTemplate from "@/components/Helpers/Pages/PageTemplate";
import BasicNav from "@/components/Helpers/Navs/BasicNav";
import {mapActions, mapState, mapGetters} from "vuex";
import ValueDisplayCard from "@/components/Helpers/Cards/ValueDisplayCard";
import BasicButton from "@/components/Helpers/Buttons/BasicButton";
import BasicModal from "../../components/Helpers/Modal/BasicModal";
import LoadingCard from "../../components/Helpers/Loading/LoadingCard";
import BasicCard from "../../components/Helpers/Cards/BasicCard";
import ToastMixin from "../../mixin/ToastMixin";

export default {
  name: "VerifoneStatus",
  components: {BasicCard, LoadingCard, BasicModal, BasicButton, ValueDisplayCard, BasicNav, PageTemplate},
  data() {
    return {
      loading: true,
      confirmModal: false,
      successModal: false,
      errorFetching: false,
    };
  },
  mixins: [ToastMixin],
  computed: {
    ...mapGetters("CashMachine", ["cashAcceptTotal"]),
    ...mapState("GeneralSettings", ["kioskDetails"]),
    ...mapState("ReceiptPrinting", ["printError", "API_KEYS_PRINT_LOG"]),
    cashTotal() {
      return this.cashAcceptTotal.total;
    },
  },
  async mounted() {
    await this.loadPage();
  },
  watch: {
    printError() {
      if(this.printError.active === true) {
        this.$toasted.error(this.printError.message, {
          position: "top-right",
          duration: 3000
        });
      }
    }
  },
  methods: {
    ...mapActions("CashMachine", ["getCashMachineTotal", "resetCashMachineTotal"]),
    ...mapActions("ReceiptPrinting", ["saveAcceptTotalBefore", "saveAcceptTotalAfter", "printReceipt", "saveKioskDetails"]),
    goBack() {
      this.$router.push({name: "Home"});
    },
    async snapShot() {
      try{
        this.loading = true;
        await this.saveAcceptTotalBefore(this.cashAcceptTotal);
        await this.saveKioskDetails(this.kioskDetails);
        this.printReceipt(this.API_KEYS_PRINT_LOG.ACCEPTOR_RECEIPT_NO_UPDATE);
      }catch (e){
        this.errorToast("Failed to print receipt");
      }finally {
        this.loading = false;
      }
    },

    async loadPage(){
      try {
        await this.getCashMachineTotal();
        this.saveAcceptTotalBefore(this.cashAcceptTotal);
      } catch (e) {
        this.errorFetching = true;
      } finally {
        this.loading = false;
      }
    },
    async confirmReset(){
      this.confirmModal = false;
      try {
        this.loading = true;
        await this.saveAcceptTotalBefore(this.cashAcceptTotal);
        await this.saveKioskDetails(this.kioskDetails);
        await this.resetCashMachineTotal(this.cashTotal);
        await this.saveAcceptTotalAfter(this.cashAcceptTotal);
        this.printReceipt(this.API_KEYS_PRINT_LOG.ACCEPTOR_RECEIPT);
        this.showSuccessModal();
      } catch (e) {
        this.errorToast("Failed to reset cash acceptor");
      } finally {
        this.loading = false;
      }
    },
    showSuccessModal(){
      this.successModal = true
      setTimeout(()=> {
        this.successModal = false;
      }, 2000);
    },
  }
}
</script>

<style scoped>

</style>