<template>
  <div class="bg-white rounded-lg p-3 shadow-lg">
    <div class="card-header flex justify-between p-3">
      <div class="card-title w-full flex justify-start ">
        <font-awesome-icon v-if="icon" class="text-orange text-center ml-1 mr-2 text-2xl" :icon="icon"  />
        <h1 class="text-xl font-medium text-orange align-middle">{{title}}</h1>
        <span class="text-xl font-medium ml-auto">{{getCurrency}}</span>
      </div>
      <div class="card-actions">
        <slot name="card-actions"></slot>
      </div>
    </div>
    <div class="bard-body p-3 border-t" v-if="hasBodySlot">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ValueDisplayCard",
  props: {
    title: {
      type: String,
      default: "",
      required: false
    },
    value: {
      type: Number,
      default: 0,
    },
    icon: {
      type: Array,
      required: false
    }
  },
  computed:{
    hasBodySlot() {
      return !!this.$slots.body
    },
    getCurrency() {
      return `$${this.value.toFixed(2)}`;
    },
  }
}
</script>

<style scoped>

</style>style>